var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c5fb41f449e6c00921a99f24e5513fe600b3896c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";
import { AxiosError } from "axios";

const APP_ENV =
  process.env.APP_ENV ||
  process.env.NEXT_PUBLIC_ENV ||
  process.env.NEXT_PUBLIC_APP_ENV ||
  process.env.NODE_ENV;
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://f97c4c68238c4701a5074aea88743fd0@o563802.ingest.sentry.io/6139665",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: APP_ENV === "production" ? 0.1 : 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: APP_ENV,
  integrations: [new Integrations.BrowserTracing()],
  beforeSend(event, hint) {
    // ignore axios network errors
    if (
      typeof hint === "object" &&
      hint.originalException.code === AxiosError.ERR_NETWORK
    ) {
      return null;
    }
    return event;
  },
});
